/* === Search Page Styles === */
.search-page {
    height: 100vh;
    overflow: hidden;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    position: relative;
}

.search-box {
    width: 100%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 20px;
    padding: 40px;
    position: relative;
    margin-top: 50px;
}

.search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 10px;

    .close-button {
        margin-right: auto;
        margin-left: 30px;
    }

    .signup-link {
        margin-left: auto;
    }
}

.signed-in-user {
    margin-left: auto;
    font-weight: 500;
    font-size: 1rem;
}

.signup-link {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 999px;
    font-weight: 600;
    text-decoration: none;
}

.search-input-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding-bottom: 20px;
    z-index: 10;
}

.search-input {
    flex: 1;
    padding: 16px;
    border-radius: 12px;
    border: none;
    background-color: #e8e8e8;
    font-size: 1.2rem;
}

.search-user-card {
    display: flex;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding: 16px;
    border-radius: 12px;
    text-decoration: none;
    margin-bottom: 16px;
}

.user-avatar {
    background-color: #fff;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;
}

.user-name {
    font-weight: bold;
    font-size: 2.5rem;
}

.user-username {
    font-size: 0.95rem;
    opacity: 0.85;
    color: #fff;
}

.user-headline {
    font-size: 0.9rem;
    color: #fff;
    opacity: 0.85;
    text-align: left;
}

.close-button {
    font-size: 1.5rem;
    color: #000;
    text-decoration: none;
    margin-right: auto;
    padding: 4px 12px;
    border-radius: 999px;
    font-weight: bold;
    cursor: pointer;
}