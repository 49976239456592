.landing-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}

.headline {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3.5rem;
    font-weight: bold;
    color: black;
    z-index: 2;
    margin: 0;
    line-height: 1.5;
}

.card {
    position: relative;
    width: 60%;
    height: 80vh;
    padding: 0;
    border: 1px solid #000000;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}


.top-right {
    position: absolute;
    top: 3rem;
    right: 3rem;
    display: flex;
    align-items: center;
    gap: 3rem;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.icon {
    width: 48px;
    height: 48px;
    color: black;
}

.signup-button {
    background: black;
    color: white;
    padding: 0rem 3rem;
    min-height: 48px;
    border-radius: 999px;
    font-weight: 600;
    text-decoration: none;
    align-items: center;
    display: flex;
}

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.outer-circle {
    position: relative;
    margin-top: 10vh;
    width: 30vw;
    height: 30vw;
    max-width: 260px;
    max-height: 260px;
    border-radius: 50%;
    animation: spin 16s linear infinite;
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 80vw;
    max-width: 700px;
    max-height: 700px;
    border-radius: 50%;
    z-index: 10;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}