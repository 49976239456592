.container {
    max-width: 1500px;
    margin: 40px auto;
    padding: 80px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: "Outfit", sans-serif;
    border: 1px solid rgba(0, 0, 0, 0.35);
}

.profileHeader {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.profileHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.imageUploadWrapper {
    position: relative;
    width: 120px;
    height: 120px;
}

.imageUploadInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
}

.profileImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ccc;
}

.editProfile label {
    display: block;
    margin: 8px 0;
    font-weight: 500;
}

.editProfile input,
.editProfile textarea {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: 1rem;
}

.button {
    padding: 8px 14px;
    margin: 6px 6px 0 0;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.5rem;
}

.button:hover {
    background-color: #555;
}

.editLinksSection {
    margin-top: 50px;
}

.linkEditItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    max-width: 300px;
}

.linkEditItem a {
    color: #0077cc;
    text-decoration: none;
    font-size: 30px;
}

.linkEditItem button {
    background-color: #cc3333;
}

.linkList {
    margin-top: 30px;
}

.circleSection {
    margin-top: 40px;
}

.centerButtons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}