/* === Reset === */
body,
h1,
h2,
p,
ul,
li,
input,
button {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}

/* === Layout === */
.dashboard-container {
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
    padding: 30px 30px 30px 60px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(0, 0, 0, 0.35);
}

.dashboard-header-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 40px;
    align-items: start;
    margin-bottom: 30px;
}

.profile-grid-left {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
}

.profile-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-name {
    font-size: 3.5rem;
    margin: 10px 10px 4px 0px;
    line-height: 1;
}


.profile-headline {
    font-size: 1.5rem;
    margin: 0 0 8px 0;
    color: #111;
}

.profile-bio {
    font-size: 1rem;
    color: #555;
    max-width: 500px;
    line-height: 1;
    margin: 0 0 12px 0;
}

.profile-grid-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.button-group {
    display: flex;
    gap: 24px;
    margin-bottom: 50px;
}

.button-group button {
    padding: 8px 24px;
    font-size: 1rem;
    border-radius: 24px;
    background-color: #000;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.button-group button:hover {
    background-color: #333;
}

.dashboard-logo {
    height: 120px;
}

.dashboard-exit-button {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
    z-index: 10;
}

.dashboard-exit-button:hover {
    transform: scale(1.1);
}

/* === Social Icons === */
.social-icons {
    display: flex;
    gap: 16px;
    margin-top: 30px;
    margin-left: 30px;
    flex-wrap: wrap;
}

.social-icons img {
    width: 36px;
    height: 36px;
    transition: transform 0.2s ease;
    cursor: pointer;
}

.social-icons img:hover {
    transform: scale(1.1);
}

/* === Links === */
.links-section {
    margin: 40px 30px;
}

.links-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.link-card {
    background-color: #e5e5e5;
    color: #000;
    border: 2.5px solid #aaa;
    border-radius: 16px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.75rem;
    box-shadow: -6px 8px 0px #000;
    width: 550px;
    max-height: 55px;
    cursor: pointer;
}

.link-card:hover {
    background-color: #dcdcdc;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.5);
}

.link-card a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
}

/* === Add Link Form === */
.add-link-form {
    display: flex;
    gap: 10px;
}

.add-link-form input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add-link-form button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.add-link-form button:hover {
    background-color: #218838;
}

.add-link-form button:disabled {
    background-color: #999;
    cursor: not-allowed;
}

/* === Circle Section === */
.circle-section ul {
    list-style: none;
    margin-top: 10px;
    padding: 0;
}

.circle-section li {
    background: #f4f4f4;
    margin-bottom: 5px;
    padding: 8px;
    border-radius: 4px;
}

/* === Spinner === */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}