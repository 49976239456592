.circle-avatars {
    display: flex;
    gap: 8px;
}

.avatar-wrapper {
    position: relative;
}

.circle-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}